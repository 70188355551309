.radar {
  display: flex;
  height: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: toRem(18);
  }

  &__label {
    font-size: toRem(16);
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  &__map {
    height: 100%;
    margin: 0 toRem(-30);
    min-height: toRem(288);

    @media (media(s-min)) {
      min-height: toRem(342);
    }

    @media (media(m-min)) {
      min-height: toRem(450);
    }

    @media (media(sl-min)) {
      min-height: toRem(643);
    }

  }
}

.expandMap {
  background: none;
  border: 0;
  font-family: $font-arthouse;
  font-size: toRem(12);
  font-weight: 700;
  letter-spacing: 0.53px;
  text-decoration: underline;
  text-transform: uppercase;

  &__icon {
    border: 1px solid $color-black;
    display: inline-block;
    height: 7px;
    margin-left: toRem(5);
    position: relative;
    top: 2px;
    width: 7px;

    .radar-expanded & {
      margin-left: toRem(12);
    }

    svg {
      display: inline-block;
      height: 6px;
      position: absolute;
      right: -10px;
      top: -7px;
      transform: rotate(45deg);

      .radar-expanded & {
        transform: rotate(-135deg);
      }
    }
  }
}
