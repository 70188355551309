@import '../../scss/core/variables';

/* stylelint-disable selector-max-specificity */
.nbc_owned_television_stations_weather_iframe {
  border: 1px solid $weather-frame-border;
  margin: toRem(20) 0;
  width: 100%;

  @media (media(m-min)) {
    max-width: toRem(300);
  }

  &:hover {
    border: 1px solid rgba(30, 110, 216, 0.3);
    box-shadow: 0 0 4px 1px rgba(30, 110, 216, 0.3);
  }

  .widget_logo {
    height: toRem(40);
    margin: toRem(10 0 0 16);
    width: auto;
  }

  h1,
  h2,
  h3 {
    font-size: toRem(20);
    line-height: 100%;
    margin: toRem(20 0 10 18);
  }

  .widget_location {
    color: $color-gray-26;
    font-family: $font-arthouse;
    font-size: toRem(11);
    font-weight: 700;
    margin: toRem(0 0 10 18);
    text-transform: uppercase;
  }

  .tpPlayer {
    height: 160px;
    margin: auto;
    width: 282px;

    .nbc-weather-widget & {
      height: 169px;
      margin: 0;
      width: 100%;
    }
  }

  .widget-weather-icon {
    display: inline-block;
  }

  .conditions {
    color: $color-gray-38;
    display: flex;
    flex-direction: row;
    font-family: $font-arthouse;
    padding: toRem(22 27 22 17);
    width: 100%;

    .current {
      color: $weather-iframe-current;
      display: flex;
      flex-direction: column;
      font-size: toRem(14);
      font-weight: 500;
      justify-content: center;
      line-height: 160%;
      padding-right: toRem(25);
      width: 60%;

      .temp {
        display: flex;
        font-size: toRem(50);
        justify-content: space-between;
        line-height: 98%;
        margin-bottom: toRem(25);

        @media (media(s-min)) {
          justify-content: normal;
        }

        @media (media(m-min)) {
          justify-content: space-between;
        }
      }

      .widget-weather-icon {
        height: toRem(50);
        max-width: toRem(50);
        width: toRem(50);

        @media (media(s-min)) {
          margin-left: toRem(26);
        }

        @media (media(m-min)) {
          margin-left: auto;
        }
      }
    }

    .tonight-tomorrow {
      flex-direction: column;
      width: 40%;
    }

    .tonight,
    .tomorrow {
      color: rgba( $weather-iframe-future, 0.75);
      font-size: toRem(11);
      font-weight: 700;
      text-transform: uppercase;

      .temp {
        color: rgba($color-gray-38, 0.79);
        display: flex;
        font-size: toRem(29);
        font-weight: 500;
        justify-content: space-between;
        margin-top: toRem(15);

        @media (media(s-min)) {
          justify-content: normal;
        }

        @media (media(m-min)) {
          justify-content: space-between;
        }

        .widget-weather-icon {
          @media (media(s-min)) {
            margin-left: toRem(26);
          }

          @media (media(m-min)) {
            margin-left: auto;
          }
        }
      }
    }

    .tomorrow {
      margin-top: toRem(25);
    }
  }

  .site-header__alerts {
    border-top: 1px solid $color-gray-08;
    display: flex;
    justify-content: center;
    margin: toRem(0 10);
    padding: toRem(15) 0;

    .site-header__weather {
      margin-right: 0;
      min-width: 0;
    }

    a {
      color: $weather-iframe-alerts;
      font-family: $font-arthouse;
      font-size: toRem(11);
      text-decoration: none;
    }
  }

  .widget_sponsor {
    align-items: center;
    background: $weather-sponsor-background;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .sponsored-ad-unit {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: toRem(4) 0;
      padding-left: toRem(15);
      padding-right: toRem(15);

      &::before {
        color: $color-white;
        content: '';
        font-family: $font-arthouse;
        font-size: toRem(10);
        font-weight: 700;
        margin-right: toRem(35);
        text-transform: uppercase;
        white-space: nowrap;
      }

      &.logo-rendered::before {
        content: attr(data-beforetext);
      }

      div {
        display: inline-block;
      }

      &:empty {
        height: 0;
        margin: 0;

        &::before {
          content: '';
        }
      }
    }
  }

  .weather-module-map {
    height: toRem(349);
    min-height: toRem(250);
    overflow: hidden;
    width: 100%;
  }

  a.weather-module__conditions {
    text-decoration: none;
  }

  .weather-module__full-forecast {
    display: flex;
    margin: toRem(5 20 20 20);
    text-align: center;
    width: calc( 100% - 40px );

    &-link {
      border: 2px solid $color-blue-12;
      color: $color-blue-12;
      font-family: $font-arthouse;
      font-size: toRem(16);
      font-weight: 700;
      padding: toRem(9);
      text-align: center;
      text-decoration: none;
      width: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
/* stylelint-enable selector-max-specificity */
